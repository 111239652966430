(function () {
    'use strict';

    angular.module('app', [
        'ui.bootstrap',
        'angular-loading-bar',
        'ngAnimate',
        'material.components.input',
        'material.components.checkbox',
        'material.components.progressCircular',
        'material.components.select',
        'material.components.datepicker',
        'material.components.icon',
        'material.components.button',
        'material.components.virtualRepeat',
        'material.components.showHide',
        'angular-input-stars',
        'google.places',
        'timer',
    ])
        .config(['cfpLoadingBarProvider', function (cfpLoadingBarProvider) {
            cfpLoadingBarProvider.includeSpinner = false; // Show the spinner.
            cfpLoadingBarProvider.includeBar = true; // Show the bar.
        }])
        .config(['$mdDateLocaleProvider', function ($mdDateLocaleProvider) {
            $mdDateLocaleProvider.formatDate = function (date) {
                return moment(date).format('DD-MM-YYYY');
            };
        }])
        .config(['$mdThemingProvider', function($mdThemingProvider) {
            $mdThemingProvider.generateThemesOnDemand(true);
        }])
        .filter('dateToISO', function () {

            return function (input) {
                return new Date(input).toISOString();
            }
        });
    // .directive('googleplace', function () {
    //     return {
    //         require: 'ngModel',
    //         link: function (scope, element, attrs, model) {
    //             var options = {
    //                 types: [],
    //                 componentRestrictions: {}
    //             };
    //             scope.gPlace = new google.maps.places.Autocomplete(element[0], options);
    //
    //             google.maps.event.addListener(scope.gPlace, 'place_changed', function () {
    //                 scope.$apply(function () {
    //                     model.$setViewValue(element.val());
    //                 });
    //             });
    //         }
    //     };
    // });

    // .factory('socket', function (socketFactory) {
    //
    //     return socketFactory();
    // })
    angular.module('app').directive('onFinishRender', ['$timeout', function ($timeout) {
        return {
            restrict: 'A',
            link: function (scope, element, attr) {
                if (scope.$last === true) {
                    $timeout(function () {
                        scope.$emit('ngRepeatFinished');
                    });
                }
            }
        }
    }]).directive('jsonld', ['$filter', '$sce', function ($filter, $sce) {
        return {
            restrict: 'E',
            template: function () {
                return "<script type='application/ld+json' ng-bind-html='onGetJson()'><\/script>";
            },
            scope: {
                json: '=json'
            },
            link: function (scope, element, attrs) {
                scope.onGetJson = function () {
                    return $sce.trustAsHtml($filter('json')(scope.json));
                }
            },
            replace: true
        };
    }]).directive('jsonldproduct', ['$filter', '$sce', function ($filter, $sce) {
        return {
            restrict: 'E',
            template: function () {
                return "<script type='application/ld+json' ng-bind-html='onGetJsonProduct()'><\/script>";
            },
            scope: {
                json: '=json'
            },
            link: function (scope, element, attrs) {
                scope.onGetJsonProduct = function () {
                    return $sce.trustAsHtml($filter('json')(scope.json));
                }
            },
            replace: true
        };
    }]);
    angular.module('app').factory('socket', ['$rootScope', function ($rootScope) {
        // var socket = io.connect('http://localhost:443');


        // var socket = io.connect('https://cityhouseapartment.vn:4443', {secure: true});

        // io.set('match origin protocol', true);
        // var socket = io.connect('https://45.77.168.159:2053');
        var socket = io.connect('https://cityhouseapartment.vn:2053');
        // var socket = io.connect('http://localhost:2053');

        return {
            on: function (eventName, callback) {
                // debugger;
                socket.on(eventName, function () {
                    var args = arguments;
                    $rootScope.$apply(function () {
                        callback.apply(socket, args);
                    });
                });
            },
            emit: function (eventName, data, callback) {

                // debugger;
                socket.emit(eventName, data, function () {
                    var args = arguments;
                    $rootScope.$apply(function () {
                        if (callback) {
                            callback.apply(socket, args);
                        }
                    });
                })
            }
        };
    }]);
})();