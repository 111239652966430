(function () {
    'use strict';
    // angular.module('app').controller('HeaderFilterController',
    //     ['$rootScope', '$scope', '$sce', 'pageService', 'cfpLoadingBar', 'socket',
    //         function ($rootScope, $scope, $sce, pageService, cfpLoadingBar, socket) {
    //             $scope.filters = {keyword: '', bedrooms: '', price: '', categories: [], amenities: []};
    //
    //             $scope.filterDatas = {
    //                 bedrooms: [
    //                     // {text: '1 bedroom', value: 1},
    //                     // {text: '2 bedrooms', value: 2},
    //                     // {text: '3 bedrooms', value: 3},
    //                     // {text: '4 bedrooms', value: 4},
    //                     // {text: '5 bedrooms', value: 5}
    //                     ],
    //                 price: [
    //                     // {text: '<500 USD', value: '0,499'},
    //                     // {text: '500 to 1000 USD', value: '500,1000'},
    //                     // {text: '1000 to 1500 USD', value: '1000,1500'},
    //                     // {text: '1500 to 2000 USD', value: '1500,2000'},
    //                     // {text: '2000 to 3000 USD', value: '2000,3000'},
    //                     // {text: '>3000 USD', value: '3000,99999'}
    //                     ],
    //                 categories: [],
    //                 amenities: [],
    //             };
    //             $scope.initSearch = function () {
    //                 $scope.getCategories();
    //                 $scope.getAmenities();
    //             };
    //             $scope.getCategories = function () {
    //                 var getData = pageService.getFilterCategories();
    //                 getData.then(function (data) {
    //                     if (data.data !== undefined) {
    //                         var categories = data.data;
    //                         categories.forEach(function (item) {
    //                             $scope.filterDatas.categories.push({text: $scope.localize(item.shortDescription), value: item.id});
    //                         });
    //                     }
    //                 });
    //             };
    //             $scope.getAmenities = function () {
    //                 var getData = pageService.getAmenities();
    //                 getData.then(function (data) {
    //                     if (data.data !== undefined) {
    //                         var models = data.data;
    //                         models.forEach(function (item) {
    //                             $scope.filterDatas.amenities.push({text: $scope.localize(item.content), value: item.id});
    //                         });
    //                     }
    //                 });
    //             };
    //             $scope.getPriceForSearch = function () {
    //                 var getData = pageService.getPriceForSearch();
    //                 getData.then(function (data) {
    //                     debugger;
    //                     if (data.data !== undefined) {
    //                         var models = data.data;
    //                         models.forEach(function (item) {
    //                             $scope.filterDatas.price.push({text: $scope.localize(item.description), value: item.value});
    //                         });
    //                     }
    //                 });
    //             };
    //             $scope.getBedroomForSearch = function () {
    //                 var getData = pageService.getBedroomForSearch();
    //                 getData.then(function (data) {
    //                     if (data.data !== undefined) {
    //                         var models = data.data;
    //                         models.forEach(function (item) {
    //                             $scope.filterDatas.bedrooms.push({text: $scope.localize(item.description), value: item.value});
    //                         });
    //                     }
    //                 });
    //             };
    //             $scope.localize = function (value) {
    //                 if (value) {
    //                     return value[currentLocale];
    //                 }
    //             };
    //         }]);

    angular.module('app').controller('SearchController',
        ['$rootScope', '$scope', '$sce', 'pageService',
            function ($rootScope, $scope, $sce, pageService) {
                var vm = this;
                //init search params
                $scope.filters = {keyword: '', bedrooms: '', price: '', categories: [], amenities: []};
                $scope.isSearchLat = false;
                $scope.filterDatas = {
                    bedrooms: [
                        // {text: 'All', value: ''},

                        // {text: '1 bedroom', value: 1},
                        // {text: '2 bedrooms', value: 2},
                        // {text: '3 bedrooms', value: 3},
                        // {text: '4 bedrooms', value: 4},
                        // {text: '5 bedrooms', value: 5}
                    ],
                    price: [
                        // {text: 'All', value: ''},
                        // {text: '<500 USD', value: '0,499'},
                        // {text: '500 to 1000 USD', value: '500,1000'},
                        // {text: '1000 to 1500 USD', value: '1000,1500'},
                        // {text: '1500 to 2000 USD', value: '1500,2000'},
                        // {text: '2000 to 3000 USD', value: '2000,3000'},
                        // {text: '>3000 USD', value: '3000,99999'}
                    ],
                    categories: [
                        // {text: 'All', value: ''},
                    ],
                    amenities: [],
                    lat: '',
                    lng: ''
                };
                $scope.paging = {total: 0, currentPage: 1, itemsPerPage: defaultPaging, data: {}};
                $scope.selectAllCategory = function () {
                    var isAllCategory = $.inArray(' ', $scope.filters.categories);
                    if (isAllCategory < 0) {
                        $scope.filterDatas.categories.forEach(function (item) {
                            $scope.filters.categories.push(item.value);
                        });
                    }
                    else {
                        $scope.filters.categories = [' '];
                    }

                };
                $scope.uncheckAllCategory = function () {
                    var index = $scope.filters.categories.indexOf(' ');
                    if (index > 0)
                        $scope.filters.categories.splice(index);
                };
                $scope.selectAllBedrooms = function () {
                    var isAllBedrooms = $.inArray(' ', $scope.filters.bedrooms);
                    if (isAllBedrooms < 0) {
                        $scope.filterDatas.bedrooms.forEach(function (item) {
                            $scope.filters.bedrooms.push(item.value);
                        });
                    }
                    else {
                        $scope.filters.bedrooms = [' '];
                    }

                };
                $scope.uncheckAllBedrooms = function () {
                    var index = $scope.filters.bedrooms.indexOf(' ');
                    if (index > 0)
                        $scope.filters.bedrooms.splice(index);
                };
                $scope.selectAllAmenities = function () {
                    var isAllAmenities = $.inArray(' ', $scope.filters.amenities);
                    if (isAllAmenities < 0) {
                        $scope.filterDatas.amenities.forEach(function (item) {
                            $scope.filters.amenities.push(item.value);
                        });
                    }
                    else {
                        $scope.filters.amenities = [' '];
                    }

                };
                $scope.uncheckAllAmenities = function () {
                    var index = $scope.filters.amenities.indexOf(' ');
                    if (index > 0)
                        $scope.filters.amenities.splice(index);
                };
                $scope.initSearch = function () {
                    $scope.getCategories();
                    $scope.getAmenities();
                    $scope.getBedroomForSearch();
                    $scope.getPriceForSearch();
                    $scope.initFilterParamFromUrl();
                    // setTimeout(function(){  }, 300);
                    $scope.filterProjects();
                    $('.section-search').removeClass('hidden').fadeIn(500);
                };
                $scope.getCategories = function () {
                    var getData = pageService.getFilterCategories();
                    getData.then(function (data) {
                        if (data.data !== undefined) {
                            var categories = data.data;
                            categories.forEach(function (item) {
                                $scope.filterDatas.categories.push({
                                    text: $scope.localize(item.shortDescription),
                                    value: item.id
                                });
                            });
                        }
                    });
                };
                $scope.getAmenities = function () {
                    var getData = pageService.getAmenities();
                    getData.then(function (data) {
                        if (data.data !== undefined) {
                            var models = data.data;
                            models.forEach(function (item) {
                                $scope.filterDatas.amenities.push({
                                    text: $scope.localize(item.content),
                                    value: item.id
                                });
                            });
                        }
                    });
                };
                $scope.getPriceForSearch = function () {
                    var getData = pageService.getPriceForSearch();
                    getData.then(function (data) {
                        if (data.data !== undefined) {
                            var models = data.data;
                            models.forEach(function (item) {
                                $scope.filterDatas.price.push({
                                    text: $scope.localize(item.description),
                                    value: item.value
                                });
                            });
                        }
                    });
                };
                $scope.getBedroomForSearch = function () {
                    var getData = pageService.getBedroomForSearch();
                    getData.then(function (data) {
                        if (data.data !== undefined) {
                            var models = data.data;
                            models.forEach(function (item) {
                                $scope.filterDatas.bedrooms.push({
                                    text: $scope.localize(item.description),
                                    value: item.value
                                });
                            });
                        }
                    });
                };
                $scope.toggleAmenity = function (item, amenities) {
                    var idx = amenities.indexOf(item);
                    if (idx > -1) {
                        amenities.splice(idx, 1);
                    }
                    else {
                        amenities.push(item);
                    }
                    $scope.filterProjects();
                };
                // $scope.sortBy = function (sort) {
                //     $scope.filters.sortBy = sort;
                //     $scope.
                // };
                $scope.filterProjects = function () {

                    var temp = $scope.filters.keyword;
                    if (Object.getPrototypeOf(temp) === Object.prototype) {
                        $scope.filters.lat = temp.geometry.location.lat();
                        $scope.filters.lng = temp.geometry.location.lng();
                        $scope.filters.keyword = temp.name;
                    }
                    // else{
                    //     $scope.filters.lat = '';
                    //     $scope.filters.lng = '';
                    // }
                    var getData = pageService.filterProjects($scope.filters, $scope.paging.currentPage, $scope.paging.itemsPerPage);
                    getData.then(function (data) {
                        $scope.paging.total = data.data.total;
                        $scope.paging.data = data.data.data;
                        setTimeout(carregarPontos($scope.paging.data, null), 500);
                        // $(document.body).scrollTop($('#apartmentSearchResult').offset().top + 300);

                        if (isPageLoaded) {
                            isPageLoaded = false;
                        }
                        else {
                            $('html, body').animate({
                                scrollTop: ($('#apartmentSearchResult').offset().top - 100) + 'px'
                            }, 600);
                        }
                        // registerHoveEffect();

                    });
                };

                $scope.localize = function (value) {
                    if (value) {
                        return value[currentLocale];
                    }
                };

                $scope.$on('ngRepeatFinished', function (ngRepeatFinishedEvent) {
                    $('.map-loading').fadeOut(500);
                    registerHoveEffect();
                });

                //#region initFilterParamFromUrl

                $scope.initFilterParamFromUrl = function () {
                    var query_string = window.location.search.substring(1);
                    var parsed_qs = $scope.parse_query_string(query_string);
                    delete parsed_qs.page;
                    delete parsed_qs.currentPage;

                    $scope.filters.keyword = parsed_qs.keyword != null && parsed_qs.keyword != '' ? parsed_qs.keyword : '';
                    $scope.filters.bedrooms = $scope.getArrayFromString(parsed_qs.bedrooms);
                    $scope.filters.sortBy = parsed_qs.sortBy != null ? parsed_qs.sortBy : 'recent';
                    //$scope.filters.bathrooms = $scope.getArrayFromString(parsed_qs.bathrooms);
                    //$scope.filters.balconies = $scope.getArrayFromString(parsed_qs.balconies);
                    //$scope.filters.furnishings = $scope.getArrayFromString(parsed_qs.furnishings);
                    $scope.filters.price = parsed_qs.price != null && parsed_qs.price != '' ? parsed_qs.price : '';
                    $scope.filters.lat = parsed_qs.lat != null && parsed_qs.lat != '' ? parsed_qs.lat : '';
                    $scope.filters.lng = parsed_qs.lng != null && parsed_qs.lng != '' ? parsed_qs.lng : '';
                    $scope.filters.categories = $scope.getArrayFromString(parsed_qs.categories);
                    $scope.filters.amenities = $scope.getArrayFromString(parsed_qs.amenities);
                    //$scope.filters.units = $scope.getArrayFromString(parsed_qs.units);
                };
                $scope.getArrayFromString = function (string) {
                    return string != null && string != ''
                        ? string.split(',')
                        : [''];
                }

                $scope.parse_query_string = function (query) {
                    var vars = query.split("&");
                    var query_string = {};
                    for (var i = 0; i < vars.length; i++) {
                        var pair = vars[i].split("=");
                        // If first entry with this name
                        if (typeof query_string[pair[0]] === "undefined") {
                            query_string[pair[0]] = decodeURIComponent(pair[1]);
                            // If second entry with this name
                        } else if (typeof query_string[pair[0]] === "string") {
                            var arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
                            query_string[pair[0]] = arr;
                            // If third or later entry with this name
                        } else {
                            query_string[pair[0]].push(decodeURIComponent(pair[1]));
                        }
                    }
                    return query_string;
                }

                //#endregion
            }]);
    angular.module('app').controller('CommentController',
        ['$rootScope', '$scope', '$sce', 'pageService', 'cfpLoadingBar',
            function ($rootScope, $scope, $sce, pageService, cfpLoadingBar) {
                var vm = this;
                $scope.isCollapsed = true;
                $scope.projectId = 0;
                $scope.commentType = '';
                $scope.comments = [];
                $scope.comment = {createdBy: currentUserId, content: '', rating: '', entityName: '', entityId: ''};
                $scope.report = {reason: '', otherReason: '', createdBy: currentUserId, commentId: ''};
                $scope.paging = {currentPage: 1, itemsPerPage: defaultPaging};
                $scope.endOfComments = false;

                $scope.googleSnippetsFlag = true;
                $scope.snippetsComment = [];

                $scope.currentProduct = {
                    productName: $scope.productName,
                    productAvatar: '',
                    productShortDesc: '',
                    rating: {
                        ratingCount: 1,
                        avgRating: 1
                    }
                };

                $scope.$watch('productName', function () {
                    $scope.currentProduct.productName = $scope.productName;
                    $scope.currentProduct.productAvatar = window.location.protocol + "//" + window.location.hostname + "/" + $scope.productAvatar;
                    $scope.currentProduct.productShortDesc = $scope.productMetaShortDesc != "" ? $scope.productMetaShortDesc : $scope.currentProduct.productName;
                });

                var scrolling = {pending: false, finished: false};
                $scope.getDetailRating = function (id, commentType) {
                    var getData = pageService.getDetailRating(id, commentType);
                    getData.then(function (data) {
                        if (data.data !== undefined) {
                            $scope.rating = data.data;
                        }

                        if ($scope.rating != '') {
                            $scope.rating.avgRating = $scope.rating.avgRating;
                        }

                        $scope.currentProduct.rating.ratingCount = ($scope.rating.ratingCount == 0 || $scope.rating.ratingCount == null) ? 0 : $scope.rating.ratingCount;
                        $scope.currentProduct.rating.avgRating = ( $scope.rating.avgRating == 0 || $scope.rating.avgRating == null) ? 0 : $scope.rating.avgRating;

                        $scope.jsonldproduct = {
                            "@context": "http://schema.org",
                            "@type": "Recipe",
                            "name": $scope.currentProduct.productName,
                            "author": "City House Apartment",
                            "image": $scope.currentProduct.productAvatar,
                            "description": $scope.currentProduct.productShortDesc,
                            "aggregateRating": {
                                "@type": "AggregateRating",
                                "bestRating": "5",
                                //"ratingCount": $scope.currentProduct.rating.ratingCount,
                                // "ratingValue": "" + $scope.currentProduct.rating.avgRating + "",
                                // "reviewCount": "" + $scope.currentProduct.rating.ratingCount + "",
                                "ratingValue": "5",
                                "reviewCount": "8686",
                                "worstRating": "1"
                            },
                            "prepTime": "PT10M",
                            "totalTime": "PT1H",
                            "recipeYield": "8",
                            "nutrition": {
                                "@type": "NutritionInformation",
                                "calories": "0 calories"
                            },
                            "recipeIngredient": [
                                $scope.currentProduct.productName
                            ]

                        };


                        if ($scope.snippetsComment.length > 0) {

                            $scope.jsonId = {
                                "@context": "http://schema.org",
                                "@type": "Review",
                                "itemReviewed": {
                                    "@type": "Recipe",
                                    "name": $scope.currentProduct.productName,
                                    "author": "City House Apartment",
                                    "image": $scope.currentProduct.productAvatar,
                                    "description": $scope.currentProduct.productShortDesc,
                                    "aggregateRating": {
                                        "@type": "AggregateRating",
                                        "bestRating": "5",
                                        //"ratingCount": $scope.currentProduct.rating.ratingCount,
                                        // "ratingValue": "" + $scope.currentProduct.rating.avgRating + "",
                                        // "reviewCount": "" + $scope.currentProduct.rating.ratingCount + "",
                                        "ratingValue": "5",
                                        "reviewCount": "8686",
                                        "worstRating": "1"
                                    },
                                    "prepTime": "PT10M",
                                    "totalTime": "PT1H",
                                    "recipeYield": "8",
                                    "nutrition": {
                                        "@type": "NutritionInformation",
                                        "calories": "0 calories"
                                    },
                                    "recipeIngredient": [
                                        $scope.currentProduct.productName
                                    ],

                                },
                                "author": $scope.snippetsComment
                            };

                        }

                    });
                };
                $scope.getComments = function (entityName, entityId) {
                    if (scrolling.pending || scrolling.finished)
                        return;
                    scrolling.pending = true;
                    var getData = pageService.getComments(entityName, entityId, $scope.paging);
                    getData.then(function (data) {
                        if (data.data === undefined || data.data.length === 0) {
                            // scrolling.composerfinished = true;
                            $('.comment-load-more-button').hide();
                            $scope.endOfComments = true;
                            return;
                        }

                        data.data.forEach(function (item) {
                            $scope.comments.push(item);
                            if ($scope.googleSnippetsFlag == true) {
                                $scope.snippetsComment.push({
                                    "@type": "Person",
                                    "name": item.fullName,
                                    "image": item.avatar,
                                    "description": item.content
                                });
                            }
                        });
                        if ($scope.snippetsComment.length <= 0) {
                            $scope.snippetsComment.push({
                                "@type": "Person",
                                "name": "Reviewer",
                                "image": "/images/snippet-logo.jpg",
                                "description": "Great Product"
                            });
                        }
                        $scope.googleSnippetsFlag = false;
                        $scope.paging.currentPage += 1;
                        scrolling.pending = false;

                    });


                };

                //console.log($scope.jsonId);
                $scope.submitComment = function (comment, entityName, entityId) {
                    comment.entityId = entityId;
                    comment.entityName = entityName;
                    if (comment.createdBy === '') {
                        swal({
                            title: '',
                            text: 'Please login to submit your comment.',
                            type: 'info'
                        }, function () {
                            $('#login-button').trigger('click');
                        });
                        return;
                    }
                    if (comment.content === '') {
                        swal({
                            title: 'Failed!',
                            text: 'Please input your comment before submit.',
                            type: 'info'
                        });
                        return;
                    }
                    if (comment.rating === '' || comment.rating == 0) {
                        swal({
                            title: 'Failed!',
                            text: 'Please give your rating before submit.',
                            type: 'info'
                        });
                        return;
                    }
                    $('#comment-submit-btn').button('loading');
                    var getData = pageService.submitComment(comment);
                    getData.then(function (data) {
                        var message = data.data;
                        if (message !== undefined && message.status === 'success') {
                            var notifyMessage = $('#notifyMessage').text();
                            // $scope.comments.unshift(message.data);
                            swal({
                                title: '',
                                text: notifyMessage,
                                //timer: 1200,
                                type: 'success',
                                html: true
                            });
                            comment.content = '';
                            comment.rating = '';
                            // $scope.getDetailRating(entityId, entityName);
                        }
                        else {
                            swal({
                                title: 'Failed!',
                                text: 'Comment is not submitted, please try later.',
                                type: 'error'
                            });
                        }
                    });
                    $('#comment-submit-btn').button('reset');
                };

                $scope.toggleReportComment = function (id) {
                    if ($scope.isCollapsed) {
                        $('#collapse-menu').appendTo('.comment-' + id);
                        $scope.report.commentId = id;
                    }

                    $scope.isCollapsed = !$scope.isCollapsed;
                };

                $scope.cancelReport = function () {
                    $scope.isCollapsed = !$scope.isCollapsed;
                };
                $scope.submitCommentReport = function (isOtherReason) {
                    if ($scope.report.createdBy === '') {
                        swal({
                            title: '',
                            text: 'Please login to submit your report.',
                            type: 'info'
                        }, function () {
                            $('#login-button').click();
                        });
                        return;
                    }
                    if (isOtherReason && $scope.report.otherReason === '') {
                        swal({
                            title: 'Failed!',
                            text: 'Please input your other reason.',
                            type: 'error'
                        });
                        return;
                    }
                    var getData = pageService.submitCommentReport($scope.report);
                    getData.then(function (data) {
                        var message = data.data;
                        if (message !== undefined && message.status === 'success') {
                            $scope.isCollapsed = !$scope.isCollapsed;
                            swal({
                                title: message.message ? message.message : 'Success',
                                text: '',
                                timer: 1200,
                                type: 'success'
                            });
                        }
                    });
                }

            }]);
    angular.module('app').controller('AuctionController',
        ['$rootScope', '$scope', '$sce', 'pageService', 'cfpLoadingBar', 'socket',
            function ($rootScope, $scope, $sce, pageService, cfpLoadingBar, socket) {
                // $scope.$on('timer-stopped', function (event, data) {
                //     window.location.href = 'http://localhost:8000';
                // });
                $scope.filterDatas = {categories: []};
                $scope.filters = {categories: [], checkinStartDate: null, checkinEndDate: null};
                $scope.localize = function (value) {
                    if (value) {
                        return value[currentLocale];
                    }
                };
                $scope.selectAllDistrict = function () {
                    var isAllCategory = $.inArray(' ', $scope.filters.categories);
                    if (isAllCategory < 0) {
                        $scope.filterDatas.categories.forEach(function (item) {
                            $scope.filters.categories.push(item.value);
                        });
                    }
                    else {
                        $scope.filters.categories = [' '];
                    }
                };
                $scope.uncheckAllCategory = function () {
                    var index = $scope.filters.categories.indexOf(' ');
                    if (index > 0)
                        $scope.filters.categories.splice(index, 1);

                };

                $scope.finishAuction = function (id) {
                    if (id === undefined) {
                        $('#place-bid-box').empty();
                        $('#place-bid-box').html('<div class="row"></div><p class="text-danger text-center">This auction is closed.</p></div>')
                    }
                    else {
                        $('#auction-box-' + id).fadeOut(300);
                    }
                };
                $scope.getCategories = function () {
                    var getData = pageService.getFilterCategories();
                    getData.then(function (data) {
                        if (data.data !== undefined) {
                            var categories = data.data;
                            categories.forEach(function (item) {
                                $scope.filterDatas.categories.push({
                                    text: $scope.localize(item.shortDescription),
                                    value: item.id
                                });
                            });
                        }
                    });
                };
                $scope.paging = {currentPage: 1, itemsPerPage: defaultPaging};
                if (channel !== undefined) {
                    socket.on(channel, function (data) {
                        // debugger;
                        if ($scope.auction != undefined && data != undefined) {
                            var newBid = JSON.parse(data);
                            $scope.auction.bids.unshift(newBid);
                            $scope.auction.bidCount += 1;
                            $scope.auction.maxPrice = newBid.amount;
                        }
                    });
                }
                $scope.loadAuctionHistory = function () {
                    var getData = pageService.getAuctionHistory();
                    getData.then(function (data) {
                        var message = data.data;
                        if (message !== undefined) {
                            $scope.biddingAuctions = message.biddingAuctions;
                            $scope.oldAuctions = message.oldAuctions;
                        }
                    });
                };
                $scope.initAuction = function (id) {
                    $('#place-bid-box').hide();
                    $scope.timerStart = false;
                    $scope.ignoreId = id;
                    // $scope.channel = 'newBid_' + id + '_' + currentUserId;
                    var getData = pageService.getAuction(id);
                    getData.then(function (data) {
                        var message = data.data;
                        if (message !== undefined) {
                            $scope.auction = message;
                            $scope.auction.endTime = moment($scope.auction.endTime).unix() * 1000;
                            // $scope.auction.endTime = (moment(new Date()).unix() + 10) * 1000;
                            $('#place-bid-box').show(500);
                            $scope.timerStart = true;

                        }
                    });
                    $scope.loadAuctions(id, {});
                };

                $scope.initAuctionList = function () {
                    var now = new Date();
                    var future = new Date();
                    future.setMonth(future.getMonth() + 6, 1);
                    $scope.filters.checkinEndDate = future;
                    $scope.filters.checkinStartDate = now;
                    $scope.loadAuctions(null, $scope.filters);
                    $scope.getCategories();
                };

                $scope.loadAuctions = function (id, filters) {
                    if (filters == undefined)
                        filters = $scope.filters;
                    var getData = pageService.loadAuctions($scope.paging, id, filters);
                    getData.then(function (data) {
                        if (data.data != undefined) {
                            $scope.paging.total = data.data.total;
                            $scope.auctions = data.data.data;
                            if ($scope.auctions != undefined && $scope.auctions.length > 0) {
                                $scope.auctions.forEach(function (item, index) {
                                    item.endTime = moment(item.endTime).unix() * 1000;
                                    item.address = jQuery.parseJSON(item.address);
                                });
                                // $scope.auctions[2].endTime = (moment(new Date()).unix()+10) * 1000;
                            }
                        }
                    });
                };
                $scope.placeBid = function (data) {
                    $('#submit-btn').button('loading');
                    if (authenticated !== '1') {
                        swal({
                            title: '',
                            text: 'Please login to submit to place your bid.',
                            type: 'info'
                        }, function () {
                            $('#submit-btn').button('reset');
                            $('#login-button').click();
                        });
                    }

                    if (data.bidAmount === undefined || data.bidAmount === '') {
                        swal({
                            title: '',
                            text: 'Please input your bid amount.',
                            // timer: 1200,
                            type: 'error'
                        });
                        $('#submit-btn').button('reset');
                        return;
                    }
                    if ($scope.auction.maxPrice > 0 && data.bidAmount < ($scope.auction.maxPrice + $scope.auction.tickSize)) {
                        swal({
                            title: 'Place bid unsuccessful',
                            text: 'Your bid must be at least $' + ($scope.auction.maxPrice + $scope.auction.tickSize),
                            // timer: 1200,
                            type: 'error'
                        });
                        $('#submit-btn').button('reset');
                        return;
                    }
                    if ($scope.auction.maxPrice == 0 && data.bidAmount < $scope.auction.startPrice) {
                        swal({
                            title: 'Place bid unsuccessful',
                            text: 'Your bid must be at least $' + $scope.auction.startPrice,
                            // timer: 1200,
                            type: 'error'
                        });
                        $('#submit-btn').button('reset');
                        return;
                    }
                    var getData = pageService.placeBid(data);
                    getData.then(function (data) {
                        var message = data.data;
                        if (message !== undefined) {
                            if (message.status === 'success') {
                                // $scope.auction.bids = message.extra;
                                data = '';
                                swal({
                                    title: '',
                                    text: message.message,
                                    timer: 1200,
                                    type: message.status
                                });
                            }
                            else {
                                swal({
                                    title: '',
                                    text: message.message,
                                    type: message.status
                                });
                            }

                        }
                        $('#submit-btn').button('reset');
                    });
                };
            }]);

})();

